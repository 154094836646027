;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "StateFactory", "channelService", "profileService", "surveyServiceNext"];
  angular.module('app.core').controller('PublicChannelStats', Controller)

  /* @ngInject */
  function Controller(
    $q,
    $state,
    StateFactory,
    channelService,
    profileService,
    surveyServiceNext
  ) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.channelId = $state.params.channelId
      ctrl.sharedBy = $state.params.sharedBy
      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()
      loadChannel()
        .then(loadProfile)
        .then(loadSurvey)
        .then(function() {
          if (ctrl.channel.getTagQuotaTotal() !== 0) {
            ctrl.tags = ctrl.channel.tags.slice()
          } else if (ctrl.survey.tagQuotaSettings.enabled) {
            ctrl.tags = ctrl.survey.getTagQuotas()
          }

          ctrl.state.ready()
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
        })
    }

    function loadChannel() {
      return channelService
        .get(ctrl.channelId)
        .then(function(channel) {
          ctrl.channel = channel
        })
        .catch(function(err) {
          return $q.reject(err)
        })
    }

    function loadProfile() {
      return profileService.get(ctrl.channel.ownerId).then(function(profile) {
        ctrl.profile = profile
      })
    }

    function loadSurvey() {
      return surveyServiceNext
        .get(ctrl.channel.survey.id)
        .then(function(survey) {
          ctrl.survey = survey
        })
    }
  }
})()
