;(function() {
  'use strict'

  angular.module('app.core').component('channelProviderInfo', {
    controller: Controller,
    templateUrl: 'channel-provider-info.html',
    bindings: {
      provider: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      //...
    }
  }
})()
