;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('public-channel-stats', {
      url: '/channel-stats/:channelId?sharedBy',
      templateUrl: 'public-channel-stats.html',
      controller: 'PublicChannelStats',
      controllerAs: '$ctrl',
      title: 'Channel Stats',
      public: true,
    })
  }
})()
