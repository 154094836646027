;(function() {
  'use strict'

  Controller.$inject = ["configService", "channelService", "clipboardService", "mediaView", "glUtils", "glDialog"];
  angular.module('app.core').component('channelLinks', {
    controller: Controller,
    templateUrl: 'channel-links.html',
    bindings: {
      channel: '<',
    },
  })

  /* @ngInject */
  function Controller(
    configService,
    channelService,
    clipboardService,
    mediaView,
    glUtils,
    glDialog
  ) {
    var ctrl = this

    ctrl.zoneLink = configService.getZoneUrl()
    ctrl.types = channelService.getTypes()
    ctrl.copyToClipboard = clipboardService.copy
    ctrl.copyChannelLink = copyChannelLink
    ctrl.copyExternalPanelLink = copyExternalPanelLink
    ctrl.getQRUrl = getQRUrl
    ctrl.expandImage = expandImage

    ctrl.$onChanges = onChanges

    function onChanges() {
      ctrl.type = _.find(ctrl.types, { value: ctrl.channel.type })
      ctrl.productsLabel =
        ctrl.channel.products.length +
        glUtils.numberSwitch(
          ctrl.channel.products.length,
          ' product',
          ' products'
        )
      ctrl.placesLabel =
        ctrl.channel.places.length +
        glUtils.numberSwitch(ctrl.channel.places.length, ' place', ' places')
    }

    function copyChannelLink(channel) {
      if (!channel.enabled) {
        return glDialog
          .confirm(
            'Copy disabled link',
            "This link is currently disabled and won't become active until the channel is live."
          )
          .then(function() {
            clipboardService.copy(channel.link.shortUrl)
          })
      }
      clipboardService.copy(channel.link.shortUrl)
    }

    function copyExternalPanelLink(link) {
      if (!ctrl.channel.enabled) {
        return glDialog
          .confirm(
            'Copy disabled link',
            "This link is currently disabled and won't become active until you start the channel."
          )
          .then(function() {
            clipboardService.copy(link)
          })
      }
      clipboardService.copy(link)
    }

    function getQRUrl(size) {
      var baseUrl = 'https://api.qrserver.com/v1/create-qr-code/'
      var size = size || 70
      var params = {
        bgcolor: 'fff',
        color: '4c4c4c',
        size: size + 'x' + size,
        data: ctrl.channel.link.shortUrl,
      }
      var urlParams
      _.each(params, function(value, key) {
        urlParams = urlParams ? urlParams + '&' : '?'
        urlParams += key + '=' + encodeURIComponent(value)
      })
      return baseUrl + urlParams
    }

    function expandImage(event) {
      event.preventDefault()
      event.stopPropagation()
      mediaView.showImage(getQRUrl(600), true)
    }
  }
})()
